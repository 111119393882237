<template>
  <div>
    <v-layout row align-center>
      <!-- backbutton -->
      <backButton v-if="prevRoute != 'login'" />
      <!-- title -->
      <h2 class="mb-4">{{ page.Name[currentLanguage] }}</h2>
      <!-- start repeating content buttons -->
      <v-layout justify-end v-if="page.RepeatingContent">
        <addButton
          v-if="
            userIsAdministrator ||
            $store.getters.hasUserRoleForPageItem({
              role: 1,
            })
          "
          class="add-button text-end mt-1"
          :toRoute="{ name: 'editPageAddItem' }"
          :label="$t('Add item')"
        />

        <v-menu
          bottom
          transition="slide-y-transition"
          offset-y
          v-model="isMenuOpen"
          :nudge-top="-8"
          v-if="userAccessMenuItemsLogic.length"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              icon
              class="mb-2 mt-1 ml-3"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ isMenuOpen ? "mdi-close" : "mdi-menu" }}</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(userAccessMenuItem, index) of userAccessMenuItemsLogic"
              :key="index"
            >
              <template v-if="userAccessMenuItem == 'editmode'">
                <div class="w-100">
                  <editmode-switch classes="mt-2" :name="$t('Edit mode')" />
                  <v-divider class="my-4" />
                </div>
              </template>
              <template v-if="userAccessMenuItem == 'exportitems'">
                <v-btn
                  depressed
                  block
                  class="ml-0 mr-3 add-button"
                  color="nonprimarybuttoncolor"
                  @click="exportDialog = true"
                >
                  {{ $t("Export items") }}
                  <v-icon right color="primaryblue">mdi-arrow-top-right</v-icon>
                </v-btn>
              </template>

              <template v-if="userAccessMenuItem == 'bulkupdate'">
                <v-btn
                  depressed
                  block
                  class="ml-0 mr-3 add-button"
                  color="nonprimarybuttoncolor"
                  @click="bulkUpdateDialog = true"
                >
                  {{ $t("Bulk update") }}
                  <v-icon right color="primaryorange">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <template v-if="userAccessMenuItem == 'deleteall'">
                <v-btn
                  depressed
                  block
                  class="ml-0 mr-3 add-button"
                  @click="deleteAllDialog = true"
                >
                  {{ $t("Delete all") }}
                  <v-icon right color="primaryred">mdi-delete</v-icon>
                </v-btn>
              </template>
              <template v-if="userAccessMenuItem == 'syncitems'">
                <v-btn
                  depressed
                  block
                  class="ml-0 mr-3 add-button"
                  color="nonprimarybuttoncolor"
                  @click="syncDialog = true"
                >
                  {{ $t("Sync items") }}
                  <v-icon right color="primary">mdi-autorenew</v-icon>
                </v-btn>
              </template>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-layout>
      <v-layout justify-end v-else>
        <editmode-switch
          v-if="userIsAdministrator && !page.Custom"
          classes="mt-0"
          :name="$t('Edit mode')"
        />
      </v-layout>
    </v-layout>
    <v-dialog v-model="exportDialog" scrollable max-width="400px">
      <v-card>
        <v-card-title>{{ $t("Export items") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5" style="height: 500px">
          <p class="mb-3">
            {{ $t("Select the items you wish to export.") }}
          </p>
          <v-form>
            <div
              v-for="(item, index) in exportItems"
              :key="'exportItems' + index"
            >
              <v-checkbox
                class="mt-0 font-weight-bold"
                v-model="item.selected"
                :label="item.title"
              ></v-checkbox>
              <v-checkbox
                class="mt-0 ml-5"
                v-for="(linkedItem, linkedItemIndex) in item.linkedExportItems"
                v-bind:key="'linkedExportItem' + linkedItemIndex + index"
                v-model="linkedItem.selected"
                :label="linkedItem.title"
              ></v-checkbox>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primaryblue"
            text
            @click="exportDialog = false"
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            depressed
            color="primaryblue"
            text
            @click="exportPageContent()"
          >
            {{ $t("Export") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="bulkUpdateDialog" scrollable max-width="600px">
      <v-card>
        <v-card-title>{{ $t("Bulk update") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-form>
            <h3>
              {{ $t("Select the property you wish to update.") }}
            </h3>

            <v-autocomplete
              :items="bulkUpdateItems"
              v-model="selectedBulkUpdateProperty"
              item-text="Title"
              return-object
              class="mb-3 mt-2"
              hide-details="auto"
              dense
              outlined
            ></v-autocomplete>

            <h3 class="mb-2">
              {{ $t("Enter or select the new value") }}
            </h3>
            <pageElement
              v-if="selectedBulkUpdateProperty != null"
              :item="selectedBulkUpdateProperty"
              :websiteId="page.WebsiteId.$oid"
              :pageId="page._id.$oid"
              :isBulk="true"
              ref="pageElement"
            />
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primaryblue"
            text
            @click="bulkUpdateDialog = false"
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            depressed
            color="primaryblue"
            text
            @click="bulkUpdatePageContent()"
            :loading="bulkUpdatnigContent"
          >
            {{ $t("Bulk update") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteAllDialog" scrollable max-width="600px">
      <v-card>
        <v-card-title>{{ $t("Delete all") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <p v-if="checkDelDisable">
            {{ $t("No items to delete!") }}
          </p>
          <p v-else>
            {{ $t("Are you sure you want to delete all items?") }}
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed text @click="deleteAllDialog = false">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            depressed
            color="primaryred"
            text
            v-on:click="deleteAll()"
            :loading="deleteAllContent"
            :disabled="checkDelDisable"
          >
            {{ $t("Delete all") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="syncDialog" persistent scrollable max-width="600px">
      <v-card>
        <v-card-title>{{ $t("Sync all items") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <p>{{ $t("Are you sure you want to sync all items?") }}</p>
          <v-row no-gutters v-if="processingSync">
            <v-col cols="12">
              <strong>{{
                `${toSyncItems.done} ${$t("of the")} 
                        ${toSyncItems.amount} ${$t("records")}`
              }}</strong>
            </v-col>
            <v-col cols="12">
              <v-progress-linear v-model="toSyncItems.percentage" height="16">
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            :disabled="processingSync"
            text
            @click="syncDialog = false"
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            depressed
            color="primaryblue"
            text
            v-on:click="syncAllItems()"
            :loading="processingSync"
            :disabled="page.ContentCount == 0"
          >
            {{ $t("Sync items") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end repeating content buttons -->
  </div>
</template>

<script>
export default {
  props: {
    tableSortBy: {
      type: String,
      default: "",
    },
    tableSortDescending: {
      type: Boolean,
    },
    prevRoute: {
      type: String,
    },
    page: {
      type: [Object, Array],
    },
    linkedPages: {
      type: [Object, Array],
    },
    searchValue: {
      type: String,
    },

    pageFilters: {
      type: [Object, Array],
    },
    checkDelDisable: {
      type: Boolean,
    },
  },
  data() {
    return {
      userIsAdministrator: this.$auth.userIsAdministrator(),
      exportDialog: false,
      bulkUpdateDialog: false,
      bulkUpdatnigContent: false,
      selectedBulkUpdateProperty: null,
      deleteAllDialog: false,
      syncDialog: false,
      deleteAllContent: false,
      processingSync: false,
      toSyncItems: { amount: 0, done: 0, percentage: 0 },
      isMenuOpen: false,
    };
  },

  computed: {
    userAccessMenuItemsLogic() {
      const userAccessMenuItems = [];
      if (this.userIsAdministrator) {
        if (!this.page.Custom) {
          userAccessMenuItems.push("editmode");
        }
        userAccessMenuItems.push(
          "exportitems",
          "bulkupdate",
          "deleteall",
          "syncitems"
        );
        return userAccessMenuItems;
      } else {
        if (this.page.AllowExportForAllUsers) {
          if (
            this.$store.getters.hasUserRoleForPageItem({
              role: 0,
            })
          ) {
            userAccessMenuItems.push("exportitems");
          }
        }
        if (this.page.AllowBulkUpdateForAllUsers) {
          if (
            this.$store.getters.hasUserRoleForPageItem({
              role: 1,
            })
          ) {
            userAccessMenuItems.push("bulkupdate");
          }
        }
      }
      return userAccessMenuItems;
    },
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
    exportItems() {
      var exportItems = this.getExportItemsFromPage(this.page);
      if (this.linkedPages) {
        for (var value in this.page.ContentDefinition) {
          if (this.page.ContentDefinition[value].LinkedPage) {
            var linkedPage = this.linkedPages.filter((obj) => {
              return (
                obj._id.$oid ==
                this.page.ContentDefinition[value].LinkedPage.$oid
              );
            })[0];
            if (linkedPage) {
              var index = exportItems.findIndex((obj) => obj.value == value);
              exportItems[index].linkedExportItems =
                this.getExportItemsFromPage(linkedPage, true);
            }
          }
        }
      }
      return exportItems;
    },
    bulkUpdateItems() {
      if (this.page.RepeatingContent) {
        var items = [];
        for (var value in this.page.ContentDefinition) {
          if (
            this.page.ContentDefinition[value].Type != "image" &&
            this.page.ContentDefinition[value].Type != "croppedimage" &&
            this.page.ContentDefinition[value].Type != "gallery" &&
            this.page.ContentDefinition[value].Type != "files"
          ) {
            items.push(this.page.ContentDefinition[value]);
          }
        }
        return items;
      }
      return [];
    },
  },
  methods: {
    getExportItemsFromPage(page, isLinkedPageItem) {
      if (page.RepeatingContent) {
        var items = [];
        for (var value in page.ContentDefinition) {
          if (
            page.ContentDefinition[value].Type != "image" &&
            page.ContentDefinition[value].Type != "croppedimage" &&
            page.ContentDefinition[value].Type != "gallery" &&
            page.ContentDefinition[value].Type != "files" &&
            (page.ContentDefinition[value].Type != "content" ||
              !isLinkedPageItem)
          ) {
            items.push({
              title: page.ContentDefinition[value].Title,
              value: value,
              selected: false,
            });
          }
        }
        return items;
      }
      return [];
    },

    deleteAll() {
      this.deleteAllContent = true;
      this.$request.delete(
        "/page-content/" + this.$route.params.id,
        null,
        (res) => {
          this.deleteAllContent = false;
          this.deleteAllDialog = false;
          if (res.success) {
            this.$emit("contentReset");
          } else {
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        }
      );
    },

    getAmountCounter(count) {
      if (count < 1000) {
        return 100;
      } else if (count < 2000) {
        return 250;
      } else if (count < 5000) {
        return 500;
      } else {
        return 1000;
      }
    },
    syncAllItems() {
      this.$request.get(
        "/pagecontentcount/" + this.$route.params.id,
        null,
        (count) => {
          if (count.success) {
            const apiCounterAmount = this.getAmountCounter(count.data);

            let processLength = Math.ceil(count.data / apiCounterAmount);
            this.toSyncItems.done = 0;
            this.toSyncItems.amount = count.data;
            this.toSyncItems.percentage = 0;
            this.processingSync = true;
            let masterdata = Object.assign(this.page.ContentDefinition);

            let requests = [];
            for (let i = 0; i < processLength; i++) {
              let itemsLength = i * apiCounterAmount;
              requests.push(
                "/pagecontent/" +
                  this.$route.params.id +
                  "?start=" +
                  itemsLength +
                  "&count=" +
                  apiCounterAmount +
                  "&sortDescending=false" +
                  "&language=" +
                  this.currentLanguage
              );
            }
            this.$request.getAll(requests, null, (res) => {
              const resLenght = res.length;
              for (let i = 0; i < resLenght; i++) {
                let contentDefinition = [];
                res[i].data.forEach((element) => {
                  //checking for all the changes in the master data items
                  for (const key in masterdata) {
                    if (
                      Object.prototype.hasOwnProperty.call(element.Data, key)
                    ) {
                      //update all the items in the object that need changes
                      this.$set(
                        element.Data,
                        key,
                        this.synchronizeObjects(
                          element.Data[key],
                          masterdata[key]
                        )
                      );
                    } else {
                      //add the object when there is no object in the element data
                      this.$set(element.Data, key, masterdata[key]);
                    }
                  }
                  //checking if there are items that has been deleted
                  for (const key in element.Data) {
                    if (!Object.hasOwnProperty.call(masterdata, key)) {
                      this.$delete(element.Data, key);
                    }
                  }
                  contentDefinition.push(element);
                });

                //Generate display labels
                for (var ii = 0; ii < contentDefinition.length; ii++) {
                  contentDefinition[ii].DisplayLabel =
                    this.$helpers.getDisplayLabel(
                      contentDefinition[ii],
                      this.page.DisplayLabelCustomFormat
                    );
                  const langs = this.$config.contentLanguages;
                  if (!contentDefinition[ii].DisplayLabels) {
                    contentDefinition[ii].DisplayLabels = {};
                  }
                  langs.forEach((lang) => {
                    contentDefinition[ii].DisplayLabels[lang] =
                      this.$helpers.getDisplayLabel(
                        contentDefinition[ii],
                        this.page.DisplayLabelCustomFormat,
                        lang
                      );
                  });
                }
                this.$request.put(
                  "/contentlist",
                  contentDefinition,
                  (result) => {
                    if (result.success) {
                      this.toSyncItems.done += res[i].data.length;
                      this.toSyncItems.percentage =
                        (this.toSyncItems.done / this.toSyncItems.amount) * 100;
                      if (this.toSyncItems.done == this.toSyncItems.amount) {
                        this.syncDialog = false;
                        this.processingSync = false;
                        location.reload();
                        const toaster = {
                          message: "Alle items zijn gesyncroniseerd",
                          timeout: false,
                          type: "SUCCESS",
                          toastClassName: [
                            "toaster-color-success",
                            "toaster-layout",
                          ],
                          bodyClassName: ["toaster-text-layout"],
                          icon: "mdi-check-circle",
                        };
                        this.$store.dispatch("setToaster", toaster);
                      }
                    } else {
                      const toaster = {
                        message: res.message,
                        timeout: false,
                        type: "ERROR",
                        toastClassName: [
                          "toaster-color-error",
                          "toaster-layout",
                        ],
                        bodyClassName: ["toaster-text-layout"],
                        icon: "mdi-alert-octagon",
                      };
                      this.$store.dispatch("setToaster", toaster);
                    }
                  }
                );
              }
            });
          } else {
            const toaster = {
              message: count,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        }
      );
    },
    synchronizeObjects(target, source) {
      for (const key in source) {
        if (
          Object.prototype.hasOwnProperty.call(target, key) &&
          key !== "Value" &&
          key !== "ValueSort"
        ) {
          if (typeof source[key] === "object" && source[key] !== null) {
            const item = this.synchronizeObjects(target[key], source[key], key);
            this.$set(target, key, item);
          } else {
            this.$set(target, key, source[key]);
          }
        } else if (key == "Value" && "files" == source["Type"]) {
          if (source.TranslatedFiles && Array.isArray(target[key])) {
            target[key] = { Nl: [...target[key]] };
          } else if (
            !source.TranslatedFiles &&
            (Array.isArray(target[key]) || typeof target[key] === "object")
          ) {
            // Extract the values of the object into an array
            const values = Object.values(target[key]);
            // Use the Array.prototype.flat() method to flatten the nested arrays
            target[key] = values.flat();
          }
        } else if (key !== "Value" && key !== "ValueSort") {
          this.$set(target, key, source[key]);
        }
      }
      for (const key in target) {
        if (
          key !== "Value" &&
          key !== "ValueSort" &&
          key !== "LinkedContent" &&
          key !== "FileAlias" &&
          !Object.hasOwnProperty.call(source, key)
        ) {
          this.$delete(target, key);
        }
      }
      return target;
    },
    bulkUpdatePageContent() {
      this.bulkUpdatnigContent = true;
      var propertyName = "";
      for (var value in this.page.ContentDefinition) {
        if (
          this.page.ContentDefinition[value].Title ==
          this.selectedBulkUpdateProperty.Title
        ) {
          propertyName = value;
        }
      }
      var queryString =
        "?sortBy=" +
        this.tableSortBy +
        "&sortDescending=" +
        this.tableSortDescending +
        "&search=" +
        (this.searchValue == null ? "" : this.searchValue) +
        this.$helpers.getPageFiltersQueryString(this.pageFilters) +
        "&propertyName=" +
        propertyName;
      this.$request.put(
        "/bulkupdatecontent/" + this.page._id.$oid + queryString,
        this.selectedBulkUpdateProperty,
        (res) => {
          if (res.success) {
            if (
              this.page.SynchronizationBulkPutUrl &&
              this.page.SynchronizationBulkPutUrl.length > 0
            ) {
              this.$request.put(
                this.page.SynchronizationBulkPutUrl +
                  "/" +
                  this.page._id.$oid +
                  queryString,
                this.selectedBulkUpdateProperty,
                (res) => {
                  if (!res.success) {
                    const toaster = {
                      message: res.message,
                      timeout: false,
                      type: "ERROR",
                      toastClassName: ["toaster-color-error", "toaster-layout"],
                      bodyClassName: ["toaster-text-layout"],
                      icon: "mdi-alert-octagon",
                    };
                    this.$store.dispatch("setToaster", toaster);
                  }
                }
              );
            }
            this.$emit("contentReset");
            this.$emit("getContent");
            this.bulkUpdateDialog = false;
            this.bulkUpdatnigContent = false;
            this.success = res.message;
          } else {
            this.bulkUpdateDialog = false;
            this.bulkUpdatnigContent = false;
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        }
      );
    },
    exportPageContent() {
      this.exportDialog = false;

      var fieldsQueryString = "";
      for (var item in this.exportItems) {
        if (this.exportItems[item].selected) {
          fieldsQueryString += "fields=" + this.exportItems[item].value + "&";
        }
        for (var linkedItem in this.exportItems[item].linkedExportItems) {
          if (this.exportItems[item].linkedExportItems[linkedItem].selected) {
            fieldsQueryString +=
              "fields=" +
              this.exportItems[item].value +
              "." +
              this.exportItems[item].linkedExportItems[linkedItem].value +
              "&";
          }
        }
      }

      var queryString =
        "?sortBy=" +
        this.tableSortBy +
        "&sortDescending=" +
        this.tableSortDescending +
        "&search=" +
        (this.searchValue == null ? "" : this.searchValue) +
        this.$helpers.getPageFiltersQueryString(this.pageFilters) +
        fieldsQueryString;

      this.$request.download(
        "/exportcontent/" + this.page._id.$oid + queryString,
        { responseType: "blob" },
        "export_ " + this.page._id.$oid + ".xlsx"
      );
    },

    setDialog() {
      this.syncDialog = true;
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 568px) {
  .add-button {
    width: 100%;
  }
}
</style>