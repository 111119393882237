<template>
  <div>
    <page-header
      ref="page-headers"
      :prevRoute="prevRoute"
      :page="page"
      :linkedPages="linkedPages"
      :searchValue="getRepeatingContentSearchVal"
      :pageFilters="getRepeatingContenthPageFilters"
      :tableSortDescending="getRepeatingContenthTableSortDescending"
      :tableSortBy="getRepeatingContenthTableSortBy"
      :checkDelDisable="getRepeatingContenthVal"
      @getContent="getRepeatingContent()"
      @contentReset="resetRepeatingContent()"
    />

    <v-row no-gutters>
      <v-col sm="12">
        <template v-if="page">
          <!-- The page has only one item -->
          <template v-if="!page.RepeatingContent && page.Custom">
            <component :is="customComponent"></component>
          </template>
          <!-- The page has no repeating objects -->
          <template v-else-if="!page.RepeatingContent">
            <non-repeating-content :page="page" @goBack="goBack" />
          </template>
          <!-- The page has repeating objects -->
          <template v-else-if="page.RepeatingContent">
            <repeating-content
              ref="repeatingContentRef"
              :page="page"
              :linkedPages="linkedPages"
              @getPage="getPage()"
              @goBack="goBack"
              @setDialog="$refs['page-headers'].setDialog()"
              @syncAllItems="$refs['page-headers'].syncAllItems()"
            />
          </template>
        </template>
        <template v-else-if="error">
          <preloader></preloader>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import MailchimpIntegration from "../integrations/MailchimpIntegration.vue";
import PageHeader from "./PageHeader.vue";
export default {
  data() {
    return {
      userToken:
        localStorage.user == null ? "" : JSON.parse(localStorage.user).Token,
      page: null,
      linkedPages: null,
      content: null,
      userIsAdministrator: this.$auth.userIsAdministrator(),
      froalaOptions: {
        toolbar: [
          "bold",
          "italic",
          "underline",
          "|",
          "align",
          "formatOL",
          "formatUL",
        ],
        toolbarMD: [
          "bold",
          "italic",
          "underline",
          "|",
          "align",
          "formatOL",
          "formatUL",
        ],
      },
      file: null,
      objectTypes: this.$config.objectTypes,
      newOptionColor: "",
      tableSortDescending: false,
      contentIsLoading: false,
      searchTimeout: null,
      dateFilterMenu: false,
      dateFilterValue: null,
      timeFilterMenu: false,
      exportDialog: false,
      bulkUpdateDialog: false,
      prevUrl: null,
      prevRoute: "",

      linkedPageContentItems: null,
    };
  },
  beforeRouteUpdate(to, from, next) {
    next((vm) => {
      vm.prevRoute = from.name;
    });
  },
  created() {
    this.$store.dispatch("setEditMode", false);
    if (this.$auth.getUser()) {
      this.getPage();
    }
  },

  watch: {
    "$route.params.id": function () {
      this.getPage();
    },

    deep: true,
    content: {
      // This will let Vue know to look inside the object
      deep: true,
      handler(newVal, oldVal) {
        if (
          newVal &&
          oldVal &&
          !this.page.RepeatingContent &&
          !this.page.Custom &&
          this.$refs.cancelConfirm
        ) {
          this.$refs.cancelConfirm.cancelDisableToggle();
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      error: "error",
      selectedWebsite: "selectedWebsite",
      editMode: "editMode",
    }),
    getLinkedPageContentItems: {
      get() {
        return this.linkedPageContentItems;
      },
      set(newItems) {
        this.linkedPageContentItems = newItems;
      },
    },
    currentLanguage: {
      get() {
        return this.$i18n.locale();
      },
    },
    customComponent() {
      return () =>
        this.page && this.page.Custom
          ? import("@/components/custom/" + this.page.UniqueName + ".vue")
          : null;
    },
    getRepeatingContentSearchVal() {
      if (!this.$refs.repeatingContentRef) {
        return null;
      }
      return this.$refs.repeatingContentRef.getSearchVal();
    },
    getRepeatingContenthVal() {
      let returnbool = false;
      if (!this.$refs.repeatingContentRef) {
        return returnbool;
      }
      const content = this.$refs.repeatingContentRef.getContenthVal();
      if (!content) {
        return true;
      }
      if (content.length == 0) {
        returnbool = true;
      }
      return returnbool;
    },
    getRepeatingContenthPageFilters() {
      if (!this.$refs.repeatingContentRef) {
        return [];
      }
      return this.$refs.repeatingContentRef.getPageFilters();
    },
    getRepeatingContenthTableSortDescending() {
      if (!this.$refs.repeatingContentRef) {
        return false;
      }
      return this.$refs.repeatingContentRef.getTableSortDescending();
    },
    getRepeatingContenthTableSortBy() {
      if (!this.$refs.repeatingContentRef) {
        return "";
      }
      return this.$refs.repeatingContentRef.getTableSortBy();
    },
  },
  components: {
    MailchimpIntegration,
    PageHeader,
  },
  methods: {
    goBack() {
      if (!this.page.RepeatingContent && !this.page.Custom) {
        location.reload();
      } else {
        this.$store.dispatch("setEditMode", false);
      }
    },
    resetRepeatingContent() {
      this.$refs.repeatingContentRef.contentReset();
    },
    getRepeatingContent() {
      this.$refs.repeatingContentRef.getContent();
    },

    getPage() {
      this.page == null;
      if (!this.$auth.loggedIn()) {
        return;
      }
      this.$request.get("/page/" + this.$route.params.id, null, (res) => {
        if (res.success) {
          if (res.data.WebsiteId.$oid == "000000000000000000000000") {
            this.$router.replace({
              name: "dashboard",
            });
            this.$router.go(1);
            return;
          }

          this.page = res.data;
          //set page for userroles
          this.$store.dispatch(
            "setUserRolesForPage",
            this.page.UsersWithAccess
          );
          this.$store.dispatch("setSelectedWebsiteById", this.page.WebsiteId);
          if (!this.page.Custom) {
            if (this.page.RepeatingContent) {
              if (this.page.ContentDefinition == null) {
                this.page.ContentDefinition = {};
              }
            }
          }

          //Get linked pages
          if (this.page.RepeatingContent && this.$route.params.id) {
            this.$request.get(
              "/linkedpages/" + this.$route.params.id,
              null,
              (res) => {
                if (res.success) {
                  this.linkedPages = res.data;
                  this.linkedPages.forEach((element) => {
                    let arr = [];
                    for (const key in element.ContentDefinition) {
                      if (
                        element.ContentDefinition[key].Type === "colorpicker"
                      ) {
                        const lang = element.ContentDefinition[key]
                          .TranslatedTitle[this.currentLanguage]
                          ? element.ContentDefinition[key].TranslatedTitle[
                              this.currentLanguage
                            ]
                          : element.ContentDefinition[key].TranslatedTitle[
                              "Nl"
                            ];
                        arr.push({ id: key, value: lang });
                      }
                    }
                    this.linkedPageContentItems = arr;
                  });
                } else {
                  const toaster = {
                    message: res.message,
                    timeout: false,
                    type: "ERROR",
                    toastClassName: ["toaster-color-error", "toaster-layout"],
                    bodyClassName: ["toaster-text-layout"],
                    icon: "mdi-alert-octagon",
                  };
                  this.$store.dispatch("setToaster", toaster);
                }
              }
            );
          }
        } else {
          const toaster = {
            message: res.message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
          this.$router.replace({ name: "pagenotfound" });
          this.$router.go(1);
        }
      });
    },
  },
};
</script>

<style scoped>
hr {
  border-top: 1px solid #bbb;
}
</style>
